import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal } from "components";
import {
    MakePaymentOnOffer,
    MakePaymentOnOfferVariables,
} from "_graphql/mutation/__generated__/MakePaymentOnOffer";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { createPayment } from "_graphql/mutation/payment";
import { useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import { useTreaty } from "hooks/data/use-treaties";



export default function CreateTreatyClaimContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const currentUser = useCurrentUser();
    const [rate, setRate] = useUrlState("rate");
    const searchParams = useAppSearch()
    const navigate = useNavigate<LocationGenerics>();
    const [create, { loading }] = useMutation<
        MakePaymentOnOffer,
        MakePaymentOnOfferVariables
    >(createPayment);
    const { loading: fetchigOffer, treaty } = useTreaty({
        treatyId: searchParams?.id || "",
    });






    const form = useFormik<any>({
        initialValues: {

        },
        onSubmit: async (variables) => {

        },
        onReset: () => {
            setOpen(false);
        },
    });




    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Make Claim"
            loading={fetchigOffer}
            description="Make a claim on this Treaty"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Making claim..." : "Make Claim"}
                    </button>
                </>
            )}
        >
            {/* {JSON.stringify(form.errors, null, 2)} */}
            {/* <TreatyClaimForm form={form} treaty={treaty} /> */}
        </Modal>
    );
}



