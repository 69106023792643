import { PlusIcon } from '@heroicons/react/24/outline';
import { ActionButton, OfficeButton, OfficeHeader, TableComponent, TableLoader } from 'components';
import config from 'config';
import { FC, useEffect, useMemo } from 'react'
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useUrlState, wrapClick } from 'utils';
import CreateContainer from './create';
import { Action } from 'components/buttons/action-button';
import ViewContainer from './view';
import UpdateContainer from './update';
import CreateManagerContainer from './add-manager';
import { Brokers_brokers } from '_graphql/queries/__generated__/Brokers';
import { useBrokers } from 'hooks/data/use-brokers';
import RemoveContainer from './remove';

type Props = {}

const BrokersPage: FC<Props> = (props) => {
    const [modal, setModal] = useUrlState("modal");
    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();


    const filter = useMemo(
        () => ({
            page: searchParams.page || 1,
            pageSize: searchParams.pageSize || 12,
            search: (searchParams.search || "").toString(),
            searchFields: ["code", "batchCode"],
            sort: searchParams.sort || "",
        }),
        [searchParams]
    );
    const { brokers, refetch, loading } = useBrokers(filter);
    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };
    const expandItem = (id: string) => {
        navigate({
            to: `./${id}`,
        });
    };

    useEffect(() => {
        navigate({
            search: (old) => ({
                ...old,
                ...(!old?.page ? { page: config.constants.page } : {}),
                ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
                ...(!old?.search ? { search: "" } : {}),
                ...(!old?.sort ? { sort: "code" } : {}),
                ...(!old?.viewType ? { viewType: "grid" } : {}),
            }),
        });
    }, [navigate]);

    return (
        <>
            <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
                <OfficeHeader renderActions={() => (
                    <OfficeButton
                        type="button"
                        onClick={wrapClick(() => setModal("create"))}
                        size='sm'
                    >
                        <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Add New Broker
                    </OfficeButton>
                )} />
                <div className="flex flex-1 overflow-y-auto">
                    <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                        <TableComponent
                            title={'brokers'}
                            hasSearch
                            data={brokers}
                            defaultView='grid'
                            renderGridLoader={() => (
                                <div className="flex space-x-3 items-center h-36 bg-gray-200 animate-pulse rounded-md">

                                </div>
                            )}

                            loading={loading}
                            refetch={refetch}
                            columns={[
                                {
                                    accessor: "re_broker_name",
                                    name: "Company Name",
                                },
                                {
                                    accessor: "re_broker_email",
                                    name: "Email",
                                },
                                {
                                    accessor: "re_broker_website",
                                    name: "Website",
                                },
                                {
                                    accessor: "re_broker_address.country",
                                    name: "Country",
                                },
                                {
                                    accessor: "",
                                    name: "Actions",
                                    render: (data: Brokers_brokers) => (
                                        <>
                                            <ActionButton action='view' onClick={dispatchAction(data?.re_broker_id || "", "view")} />
                                            <ActionButton action='expand' onClick={() => expandItem(data?.re_broker_id || "")} />
                                            <ActionButton action='update' onClick={dispatchAction(data?.re_broker_id || "", "update")} />
                                            <ActionButton action='assign' tooltip='Add Representative' onClick={dispatchAction(data?.re_broker_id || "", "assign")} />
                                            <ActionButton action='remove' onClick={dispatchAction(data?.re_broker_id || "", "remove")} />
                                        </>
                                    )
                                },

                            ]}
                            renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1]} />}
                        />
                    </div>
                </div>
            </main>

            <CreateContainer
                open={modal === "create"}
                setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
                refetch={refetch}
            />


            {searchParams.id && (
                <>
                    <ViewContainer
                        open={modal === "view"}
                        setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
                    />
                    <UpdateContainer
                        open={modal === "update"}
                        setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
                        refetch={refetch}
                    />
                    <CreateManagerContainer
                        open={modal === "assign"}
                        setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
                        refetch={refetch}
                    />
                    <RemoveContainer
                        open={modal === "remove"}
                        setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
                        refetch={refetch}
                    />
                </>
            )}
        </>

    )
}

export default BrokersPage