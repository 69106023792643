import { OfficeButton, OfficeHeader, Show } from "components";
import { FC, useEffect, useMemo } from "react";
import { Link, useMatch } from "react-location";
import { classNames, useUrlState } from "utils";
import OfferOverView from "./overview";
import { LocationGenerics } from "router/location";
import AddParticipantsContainer from "./add-participants";
import TreatyBrokers from "./tabs/intermediaries";
import TreatyReinsurersAssociates from "./tabs/reinsurers-associates";
import TreatyBrokersAssociates from "./tabs/intermediaries-associates";
import AddIntermediariesContainer from "./add-intermediaries";
import { useTreaty } from "hooks/data/use-treaties";
import AddQuarterContainer from "./add-quarter";
import Payments from "./payments";
import { useAppNavigate } from "_graphql/cache/auth";
import { TreatyType } from "_graphql/__generated__/globalTypes";
import TreatyReinsurers from "./tabs/reinsurers";
import ModifyDeductionContainer from "./modify-deductions";
import CreateAdjustmentStatementContainer from "./add-adjustment-statement";
import NonProportionalReceivablePayments from "./tabs/non-proportional-receivable-payments";

type Props = {};

const tabs = [
  { name: "Reinsurers", href: "participants" },
  { name: "Reinsurer Associates", href: "reinsurers" },
  { name: "Intermediaries ", href: "brokers" },
  { name: "Intermediaries's Associates", href: "associates" },
];
const payments = (type?: TreatyType) => [{ name: "Payments", href: "payments" }, ...(type === TreatyType.NONPROPORTIONAL ? [{ name: "Receivable Payments", href: "receivables" }] : [])];

const ViewTreatyPage: FC<Props> = (props) => {
  const navigate = useAppNavigate();
  const { params } = useMatch<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const { treaty } = useTreaty({
    treatyId: params?.treaty,
  });
  const [activeTab] = useUrlState("activeTab");
  const isProportional =
    treaty?.treaty_program?.treaty_type === TreatyType.PROPORTIONAL;
  const allowQuaerterlyFiling =
    (treaty?.treaty_participants &&
      treaty?.treaty_participants?.length > 0 &&
      treaty?.treaty_participants?.every(
        (participant) => participant?.treaty_participation_percentage
      )) ||
    false;

  const allowAdjustment: boolean = useMemo(() => {
    if (treaty?.treaty_participants?.length) {
      return treaty?.treaty_participants?.every(
        (el) => el?.treaty_participation_percentage
      );
    }
    return false;
  }, [treaty]);

  useEffect(() => {
    if (!treaty) return;
    if (treaty?.treaty_program?.treaty_type === TreatyType.NONPROPORTIONAL) {
      navigate({
        search(prev) {
          return {
            ...prev,
            layer: "1",
          };
        },
      });
    }
  }, [treaty]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <>
            <OfficeButton onClick={() => setModal("create-reinsurers")}>
              <span className="hidden sm:inline">Create Reinsurers List</span>
            </OfficeButton>
            <OfficeButton onClick={() => setModal("create-intermediaries")}>
              <span className="hidden sm:inline">Create Intermediary List</span>
            </OfficeButton>
            <Show if={allowQuaerterlyFiling && isProportional}>
              <OfficeButton onClick={() => setModal("add-quarter")}>
                <span className="hidden sm:inline">
                  File Quarterly Statement
                </span>
              </OfficeButton>
            </Show>
            <Show if={allowAdjustment && !isProportional}>
              <OfficeButton
                onClick={() => setModal("add-adjustment-statement")}
              >
                <span className="hidden sm:inline">
                  Create Adjustment Statement
                </span>
              </OfficeButton>
            </Show>
          </>
        )}
      />
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            <Link
              search={(old) => ({
                ...old,
                activeTab: undefined,
              })}
              className={classNames(
                !activeTab
                  ? "border-gray-200 text-gray-200"
                  : "border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!activeTab ? "page" : undefined}
            >
              Overview
            </Link>
            {tabs.map((_activeTab) => (
              <Link
                key={_activeTab.name}
                search={(old) => ({
                  ...old,
                  activeTab: _activeTab.href,
                })}
                className={classNames(
                  activeTab === _activeTab.href
                    ? "border-gray-200 text-gray-200"
                    : "border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  activeTab === _activeTab.href ? "page" : undefined
                }
              >
                {_activeTab.name}
              </Link>
            ))}

            <div className="h-6 w-px bg-gray-300" />
            {payments(treaty?.treaty_program?.treaty_type || TreatyType.PROPORTIONAL).map((_activeTab) => (
              <Link
                key={_activeTab.name}
                search={(old) => ({
                  ...old,
                  activeTab: _activeTab.href,
                })}
                className={classNames(
                  activeTab === _activeTab.href
                    ? "border-gray-200 text-gray-200"
                    : "border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  activeTab === _activeTab.href ? "page" : undefined
                }
              >
                {_activeTab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0 overflow-hidden overflow-y-auto light flex">
          {!activeTab && (
            <OfferOverView allowQuaerterlyFiling={allowQuaerterlyFiling} />
          )}
          {activeTab === "brokers" && <TreatyBrokers />}
          {activeTab === "participants" && <TreatyReinsurers />}
          {activeTab === "reinsurers" && <TreatyReinsurersAssociates />}
          {activeTab === "associates" && <TreatyBrokersAssociates />}
          {activeTab === "payments" && (
            <Payments treaty_type={treaty?.treaty_program?.treaty_type || ""} />
          )}
          {activeTab === "receivables" && (
            <NonProportionalReceivablePayments />
          )}
        </div>
      </div>

      {modal && (
        <>
          <AddParticipantsContainer
            open={modal === "create-reinsurers"}
            setOpen={(val: boolean) =>
              setModal(val ? "create-reinsurers" : undefined)
            }
          />
          <AddIntermediariesContainer
            open={modal === "create-intermediaries"}
            setOpen={(val: boolean) =>
              setModal(val ? "create-intermediaries" : undefined)
            }
          />
          <ModifyDeductionContainer
            open={modal === "modify-deduction"}
            setOpen={(val: boolean) =>
              setModal(val ? "create-intermediaries" : undefined)
            }
          />
          <AddQuarterContainer
            open={modal === "add-quarter"}
            setOpen={(val: boolean) => {
              setModal(val ? "add-quarter" : undefined);
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    // modal: undefined,
                    id: undefined,
                  };
                },
              });
            }}
          />
          <CreateAdjustmentStatementContainer
            open={modal === "add-adjustment-statement"}
            setOpen={(val: boolean) => {
              setModal(val ? "add-adjustment-statement" : undefined);
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    // modal: undefined,
                    id: undefined,
                  };
                },
              });
            }}
          />
        </>
      )}
    </main>
  );
};

export default ViewTreatyPage;
