import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { PaymentForm } from "components/forms";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer } from "hooks/data/use-offers";
import { useEffect, useMemo } from "react";
import numeral from "numeral";
import { updateOfferParticipantClaimPayment } from "_graphql/mutation/payment";
import { useCurrentUser } from "_graphql/cache/auth";
import { UpdateOfferParticipantClaimPayment, UpdateOfferParticipantClaimPaymentVariables } from "_graphql/mutation/__generated__/UpdateOfferParticipantClaimPayment";
import moment from "moment";
import { useOfferClaimSharePayments } from "hooks/data/use-offer-claims";

export const paymentValidationSchema = (amountToPay: number) =>
  Yup.object().shape({
    payment_type: Yup.string()
      .oneOf(["Bank Transfer", "Cheque"])
      .required("Invalid Payment Type"),
    payment_from: Yup.object()
      .when("payment_type", {
        is: "Cheque",
        then: (schema) =>
          schema.shape({
            bank_name: Yup.string().required("Bank Name is required"),
            cheque_number: Yup.string().required("Cheque Number is required"),
            date_on_cheque: Yup.string().required("Date on cheque is required"),
          }),
        otherwise: (schema) =>
          schema
            .shape({
              bank_name: Yup.string().required("Bank Name is required"),
            })
            .required(),
      })
      .required(),
    payment_to: Yup.string().required("Beneficiary Bank Name is required"),
    payment_details: Yup.object()
      .shape({
        conversion: Yup.object().shape({
          rate: Yup.number().min(0, "Number must be greater than 0")
            .min(0.0001, "Rate cannot be less than 1")
            .required("Rate is required"),
          currency: Yup.string().required("Currency is required"),
        }),
        payment_amount: Yup.number().min(0, "Number must be greater than 0")
          .min(1, "Cannot make payment less than 1")
          .max(
            numeral(amountToPay).value() || 0,
            `Amount cannot be more than ${numeral(amountToPay).value() || 0}`
          )
          .required("Payment Amount is required"),
        currency: Yup.string().required("Currency is required"),
      })
      .required(),
    offer_payment_comment: Yup.string().required("Payment Comment is required"),
  });

export default function UpdateInsurerPaymentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const currentUser = useCurrentUser();
  const [rate] = useUrlState("rate");
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [create, { loading }] = useMutation<
    UpdateOfferParticipantClaimPayment,
    UpdateOfferParticipantClaimPaymentVariables
  >(updateOfferParticipantClaimPayment, {
    refetchQueries: ["GetOfferClaimParticipantPayments"],
  });
  const { loading: fetchigOffer, offer } = useOffer({
    offer_id: params.offer || "",
  });
  const { payments, loading: fetchingPayments } = useOfferClaimSharePayments({
    offerClaimParticipantId: params?.share || "",
  });
  const currentPayment = useMemo(
    () =>
      payments?.find((payment) => payment?.offer_claim_participant_payment_id == searchParams.id),
    [payments, searchParams.id]
  );
  const oldPayments = useMemo(
    () => payments?.reduce((acc, curr) => acc + (curr?.claim_share_payment_amount || 0), 0),
    [payments]
  );
  const amountToPay = useMemo(
    () =>
      numeral(offer?.fac_premium)
        .subtract(numeral(offer?.commission_amount).add(oldPayments).value())
        .add(currentPayment?.claim_share_payment_amount || 0)
        .value() || 0,
    [offer, oldPayments]
  );
  const form = useFormik<any>({
    initialValues: {
      payment_type: "",
      payment_from: {
        bank_name: "",
        cheque_number: "",
        date_on_cheque: "",
      },
      offer_payment_comment: "",
      payment_to: "",
      payment_details: {
        payment_amount: 0,
        currency: "USD",
        conversion: {
          rate: 1,
          currency: "",
          addExchangeRate: false,
        },
      },
    },
    validationSchema: paymentValidationSchema(
      numeral(amountToPay).multiply(rate).value() || 0
    ),
    onSubmit: async (variables) => {
      await create({
        variables: {
          offerClaimParticipantPaymentId: searchParams?.id?.toString() || "",
          claimPayments: {
            claim_share_payment_amount: variables.payment_details.payment_amount,
            offer_claim_participant: params?.share?.toString() || "",
            payment_date: moment().format("YYYY-MM-DD"),
            payment_comment: variables.offer_payment_comment,
            payment_details: JSON.stringify({
              employee_id: currentUser?.employee?.employee_id,
              currency: variables.payment_details.currency,
              payment_type: variables.payment_type,
              payment_from: variables.payment_from,
              payment_to: variables.payment_to,
              conversion: variables.payment_details.conversion,
            }),
            // auto_payment_receipt: true,
          },
        },
      }).then(({ data }) => {
        if (data?.updateOfferParticipantClaimPayment) {
          toast.success("Payment Updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to update Payment");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (currentPayment) {
      const paymentDetails = JSON.parse(currentPayment.payment_details || "{}");
      console.log(paymentDetails);
      form.setValues({
        addExchangeRate: paymentDetails?.conversion?.addExchangeRate,
        payment_type: paymentDetails.payment_type,
        payment_from: paymentDetails.payment_from,
        offer_payment_comment: currentPayment.payment_comment,
        payment_to: paymentDetails.payment_to,
        payment_details: {
          payment_amount: currentPayment.claim_share_payment_amount,
          currency:
            paymentDetails.conversion?.currency || paymentDetails?.currency,
          conversion: {
            rate: paymentDetails.conversion?.rate || 1,
            currency: paymentDetails.conversion?.currency || "",
            addExchangeRate: paymentDetails?.conversion?.addExchangeRate,
          },
        },
      });
    }
  }, [currentPayment]);

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search(prev) {
            return {
              ...prev,
              rate: 1,
              modal: undefined,
            };
          },
        });
      }}
      title="Update Payment"
      loading={fetchigOffer || fetchingPayments}
      description="Provide the details to add a new payment record"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating payment..." : "Update Payment"}
          </button>
        </>
      )}
    >
      <PaymentForm form={form} offer={offer} amountToPay={amountToPay} />
    </Modal>
  );
}
