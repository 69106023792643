import { useQuery } from "@apollo/client";
import {
  FetchTreatyAccounts,
  FetchTreatyAccountsVariables,
} from "_graphql/queries/__generated__/FetchTreatyAccounts";
import { GetNonProportionalReceivablePayments, GetNonProportionalReceivablePaymentsVariables } from "_graphql/queries/__generated__/GetNonProportionalReceivablePayments";
import { GetNonProportionalTreatyPayment, GetNonProportionalTreatyPaymentVariables } from "_graphql/queries/__generated__/GetNonProportionalTreatyPayment";
import {
  GetNonProportionalTreatyPayments,
  GetNonProportionalTreatyPaymentsVariables,
} from "_graphql/queries/__generated__/GetNonProportionalTreatyPayments";
import { GetProportionalTreatyPayment, GetProportionalTreatyPaymentVariables } from "_graphql/queries/__generated__/GetProportionalTreatyPayment";
import {
  GetProportionalTreatyPayments,
  GetProportionalTreatyPaymentsVariables,
} from "_graphql/queries/__generated__/GetProportionalTreatyPayments";
import {
  GetTreaties,
  GetTreatiesVariables,
} from "_graphql/queries/__generated__/GetTreaties";
import {
  GetTreatyBrokerAssociates,
  GetTreatyBrokerAssociatesVariables,
} from "_graphql/queries/__generated__/GetTreatyBrokerAssociates";
import {
  GetTreatyBrokerParticipation,
  GetTreatyBrokerParticipationVariables,
} from "_graphql/queries/__generated__/GetTreatyBrokerParticipation";
import {
  GetTreatyBrokersParticipants,
  GetTreatyBrokersParticipantsVariables,
} from "_graphql/queries/__generated__/GetTreatyBrokersParticipants";
import {
  GetTreatyReinsurerAssociates,
  GetTreatyReinsurerAssociatesVariables,
} from "_graphql/queries/__generated__/GetTreatyReinsurerAssociates";
import {
  GetTreatyReinsurerParticipants,
  GetTreatyReinsurerParticipantsVariables,
} from "_graphql/queries/__generated__/GetTreatyReinsurerParticipants";
import {
  GetTreatyReinsurerParticipation,
  GetTreatyReinsurerParticipationVariables,
} from "_graphql/queries/__generated__/GetTreatyReinsurerParticipation";
import { Treaty, TreatyVariables } from "_graphql/queries/__generated__/Treaty";
import {
  fetchTreatyAccounts,
  getNonProportionalReceivablePayments,
  getNonProportionalTreatyPayment,
  getNonProportionalTreatyPayments,
  getProportionalTreatyPayment,
  getProportionalTreatyPayments,
  getTreaties,
  getTreaty,
  getTreatyBrokerAssociates,
  getTreatyBrokerParticipation,
  getTreatyBrokersParticipants,
  getTreatyReinsurerAssociates,
  getTreatyReinsurerParticipants,
  getTreatyReinsurerParticipation,
} from "_graphql/queries/treaties";

export const useTreaties = (variables: GetTreatiesVariables) => {
  const { data, ...rest } = useQuery<GetTreaties, GetTreatiesVariables>(
    getTreaties,
    {
      variables: variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const treaties = data?.rows || [];

  return { treaties, count: data?.count || 0, ...rest };
};

export const useNonProportionalPayments = (
  variables: GetNonProportionalTreatyPaymentsVariables
) => {
  const { data, ...rest } = useQuery<
    GetNonProportionalTreatyPayments,
    GetNonProportionalTreatyPaymentsVariables
  >(getNonProportionalTreatyPayments, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payments = data?.getNonProportionalTreatyPayments || [];
  return { payments, ...rest };
};

export const useReceivablePayments = (
  variables: GetNonProportionalReceivablePaymentsVariables
) => {
  const { data, ...rest } = useQuery<
    GetNonProportionalReceivablePayments,
    GetNonProportionalReceivablePaymentsVariables
  >(getNonProportionalReceivablePayments, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payments = data?.rows || [];
  return {
    payments,
    length: data?.count,
    ...rest
  };
};
export const useNonProportionalPayment = (
  variables: GetNonProportionalTreatyPaymentVariables
) => {
  const { data, ...rest } = useQuery<
    GetNonProportionalTreatyPayment,
    GetNonProportionalTreatyPaymentVariables
  >(getNonProportionalTreatyPayment, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payment = data?.getNonProportionalTreatyPayment;
  return { payment, ...rest };
};

export const useProportionalPayments = (
  variables: GetProportionalTreatyPaymentsVariables
) => {
  const { data, ...rest } = useQuery<
    GetProportionalTreatyPayments,
    GetProportionalTreatyPaymentsVariables
  >(getProportionalTreatyPayments, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payments = data?.getProportionalTreatyPayments || [];
  return { payments, ...rest };
};

export const useProportionalPayment = (
  variables: GetProportionalTreatyPaymentVariables
) => {
  const { data, ...rest } = useQuery<
    GetProportionalTreatyPayment,
    GetProportionalTreatyPaymentVariables
  >(getProportionalTreatyPayment, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payment = data?.getProportionalTreatyPayment;
  return { payment, ...rest };
};

export const useTreaty = (variables: TreatyVariables) => {
  const { data, ...rest } = useQuery<Treaty, TreatyVariables>(getTreaty, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { treaty: data?.treaty, ...rest };
};

export const useTreatyReinsurers = (
  variables: GetTreatyReinsurerParticipantsVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyReinsurerParticipants,
    GetTreatyReinsurerParticipantsVariables
  >(getTreatyReinsurerParticipants, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { reinsurers: data?.getTreatyReinsurerParticipants || [], ...rest };
};

export const useTreatyReinsurer = (
  variables: GetTreatyReinsurerParticipationVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyReinsurerParticipation,
    GetTreatyReinsurerParticipationVariables
  >(getTreatyReinsurerParticipation, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { participant: data?.getTreatyReinsurerParticipation, ...rest };
};
export const useTreatyBroker = (
  variables: GetTreatyBrokerParticipationVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyBrokerParticipation,
    GetTreatyBrokerParticipationVariables
  >(getTreatyBrokerParticipation, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  return { participant: data?.getTreatyBrokerParticipation, ...rest };
};

export const useTreatyReinsurerAssociates = (
  variables: GetTreatyReinsurerParticipantsVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyReinsurerAssociates,
    GetTreatyReinsurerAssociatesVariables
  >(getTreatyReinsurerAssociates, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { associates: data?.getTreatyReinsurerAssociates || [], ...rest };
};

export const useTreatyBrokers = (
  variables: GetTreatyBrokersParticipantsVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyBrokersParticipants,
    GetTreatyBrokersParticipantsVariables
  >(getTreatyBrokersParticipants, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { brokers: data?.getTreatyBrokersParticipants || [], ...rest };
};

export const useTreatyBrokersAssociates = (
  variables: GetTreatyBrokerAssociatesVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyBrokerAssociates,
    GetTreatyBrokerAssociatesVariables
  >(getTreatyBrokerAssociates, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { brokers: data?.getTreatyBrokerAssociates || [], ...rest };
};

export const useTreatyAccounts = (variables: FetchTreatyAccountsVariables) => {
  const { data, ...rest } = useQuery<
    FetchTreatyAccounts,
    FetchTreatyAccountsVariables
  >(fetchTreatyAccounts, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return { accounts: data?.fetchTreatyAccounts || [], ...rest };
};
