import { FormItem } from "components/core/step-form-builder";
import TreatyClaimForm from "./treaty-claim-form";
import TreatyClaimFormSummary from "./treaty-claim-form-summary";
import TreatySearchForm from "./treaty-form";
import TreatySearchFormSummary from "./treaty-form-summary";



export const createTreatyFormItems: FormItem[] = [
    {
        accessor: "treaty",
        description: "Select Treaty to add new claim",
        name: "Treaty Details",
        FormComponent: TreatySearchForm,
        SummaryComponent: TreatySearchFormSummary
    },
    {
        accessor: "claim",
        description: "Provide details to add new claim",
        name: "Claim Details",
        FormComponent: TreatyClaimForm,
        SummaryComponent: TreatyClaimFormSummary,
    },

];