import { useMutation, useQuery } from "@apollo/client";
import {
  AdminResetEmployeePassword,
  AdminResetEmployeePasswordVariables,
} from "_graphql/mutation/__generated__/AdminResetEmployeePassword";
import { resetEmployee } from "_graphql/mutation/employee";
import {
  Employee,
  EmployeeVariables,
} from "_graphql/queries/__generated__/Employee";
import { Employees, Employees_rows } from "_graphql/queries/__generated__/Employees";
import { getEmployee, getEmployees } from "_graphql/queries/employees";
import _ from "lodash";

export const useEmployees = (variables?: any) => {
  const { offset, limit, search } = variables || {
    offset: 0,
    limit: 500,
    search: ''
  };
  const { data, ...rest } = useQuery<Employees>(getEmployees, {
    // variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });


  const items: Employees_rows[] =
    data?.rows
      ?.filter((el) => {
        return !_.isEmpty(search) ? true : (
          el?.employee_first_name?.toLowerCase().includes(search?.toLowerCase()) ||
          el?.employee_last_name?.toLowerCase().includes(search?.toLowerCase()) ||
          el?.employee_email?.toLowerCase().includes(search?.toLowerCase())
        );
      })
      .slice(offset, offset + limit) || [];


  // console.log("data", data?.rows, offset, limit, search ?? "no search");
  // console.log("items", items);
  return {
    employees: items,
    length: data?.count || 0,
    ...rest,
  };
};

export const useEmployee = (variables: EmployeeVariables) => {
  const { data, ...rest } = useQuery<Employee, EmployeeVariables>(getEmployee, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    employee: data?.employee || null,
    ...rest,
  };
};

export const useResetEmployee = () => {
  const [reset, rest] = useMutation<
    AdminResetEmployeePassword,
    AdminResetEmployeePasswordVariables
  >(resetEmployee);

  return {
    resetCredential: reset,
    ...rest,
  };
};
