import { useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, OfficeButton } from "components";
import * as Yup from "yup";
import { OfferForm } from "components/forms";
import { currentUserVar } from "_graphql/cache/auth";
import { updateOffer } from "_graphql/mutation/offers";
import { useOffer } from "hooks/data/use-offers";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import _ from "lodash";
import {
  UpdateInputOffer,
  UpdateInputOfferVariables,
} from "_graphql/mutation/__generated__/UpdateInputOffer";
import { wrapClick } from "utils";

export const valiadationSchema = Yup.object().shape({
  class_of_business_id: Yup.object().required("Business is required"),
  offer_details: Yup.array().notRequired(),
  insurer_id: Yup.string().required("Insurer is required"),
  premium: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Premium is required"),
  policy_number: Yup.string().required("Policy Number is required"),
  insured_by: Yup.string().required("Insured is required"),
  sum_insured: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Sum Insured is required"),
  rate: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Rate is required"),
  facultative_offer: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Facultative Offer is required"),
  commission: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Commission is required"),
  brokerage: Yup.number()
    .min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Brokerage is required"),
  currency: Yup.string().required("Currency is required"),
  period_of_insurance_from: Yup.date().required(
    "Insurance Start Date is required"
  ),
  period_of_insurance_to: Yup.date()
    .min(Yup.ref("period_of_insurance_from"), "End Date must be greater than Start Date")
    .required("Insurance End Date is required"),
  comment: Yup.string().notRequired(),
});

export default function UpdateOfferContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const user = useReactiveVar(currentUserVar);
  const { loading: fetching, offer } = useOffer({
    offer_id: searchParams.id || "",
  });
  const [create, { loading }] = useMutation<
    UpdateInputOffer,
    UpdateInputOfferVariables
  >(updateOffer);

  const form = useFormik<any>({
    initialValues: {
      class_of_business_id: "",
      offer_details: [],
      insurer_id: "",
      premium: "",
      policy_number: "",
      insured_by: "",
      sum_insured: "",
      rate: "",
      facultative_offer: "",
      commission: "",
      brokerage: "",
      currency: "",
      period_of_insurance_from: "",
      period_of_insurance_to: "",
      comment: "",
      old_class_of_business_id: "",
      old_offer_detail: [],
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          offerDetailId: offer?.offer_detail?.offer_detail_id || "",
          offerId: offer?.offer_id || "",
          offerInput: {
            brokerage: parseFloat(variables.brokerage),
            insurer_id: variables.insurer_id,
            premium: parseFloat(variables.premium),
            policy_number: variables.policy_number,
            offer_details: JSON.stringify(variables.offer_details),
            class_of_business_id:
              variables.class_of_business_id?.class_of_business_id,
            employee_id: user?.employee?.employee_id ?? "",
            rate: parseFloat(variables.rate),
            commission: parseFloat(variables.commission),
            facultative_offer: parseFloat(variables.facultative_offer),
            sum_insured: parseFloat(variables.sum_insured),
            insured_by: variables.insured_by,
            currency: variables.currency,
            offer_comment: variables.comment,
            period_of_insurance_from: variables.period_of_insurance_from,
            period_of_insurance_to: variables.period_of_insurance_to,
          },
        },
      }).then(({ data }) => {
        if (data?.updateInputOffer) {
          toast.success("Offer updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.success("Could not update Offer");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (offer) {
      form.setValues({
        class_of_business_id: offer.classofbusiness,
        old_class_of_business_id: offer.classofbusiness?.class_of_business_id,
        offer_details: JSON.parse(offer.offer_detail?.offer_details || "[]"),
        old_offer_detail: JSON.parse(offer.offer_detail?.offer_details || "[]"),
        insurer_id: offer.insurer?.insurer_id,
        premium: offer.premium,
        policy_number: offer?.offer_detail?.policy_number,
        insured_by: offer?.offer_detail?.insured_by,
        sum_insured: offer.sum_insured,
        rate: offer.rate,
        facultative_offer: offer.facultative_offer,
        commission: offer.commission,
        brokerage: offer.brokerage,
        currency: offer.offer_detail?.currency,
        period_of_insurance_from: offer.offer_detail?.period_of_insurance_from,
        period_of_insurance_to: offer.offer_detail?.period_of_insurance_to,
        comment: offer?.offer_detail?.offer_comment || "",
      });
    }
  }, [offer]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      loading={fetching}
      title="Update Offer"
      description="Provide the details to update offer"
      renderActions={() => (
        <>
          <OfficeButton
            disabled={loading}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating Offer..." : "Update Offer"}
          </OfficeButton>
        </>
      )}
    >
      <OfferForm form={form} />
    </Modal>
  );
}
