import { FC } from "react";
import _ from "lodash";
import ReactQuill from "react-quill";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "react-quill/dist/quill.snow.css";

type Props = {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
};

const RichEditor: FC<Props> = ({
  id,
  disabled,
  required,
  values,
  handleChange,
  placeholder,
  label,
  errors,
  touched,
}) => {
  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-600 mb-1"
      >
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <div>
        <CKEditor
          data={_.get(values, id, "")}
          editor={ClassicEditor}
          onChange={(_, editor) => {
            handleChange({
              target: {
                name: id,
                value: editor.getData(),
              },
            });
          }}
        // placeholder={placeholder || "Type your description ..."}
        />
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default RichEditor;
