import { UserPlusIcon } from "@heroicons/react/24/outline";
import {
  ActionButton,
  Avatar,
  OfficeButton,
  OfficeHeader,
  Paginator,
  TableComponent,
  TableLoader,
} from "components";
import config from "config";
import { useInsurers } from "hooks/data/use-insurers";
import { FC, useEffect, useMemo } from "react";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState, withRoles, wrapClick } from "utils";
import CreateEmployeeContainer from "./create";
import { Action } from "components/buttons/action-button";
import ViewEmployeeContainer from "./view";
import { Insurers_rows } from "_graphql/queries/__generated__/Insurers";
import UpdateInsurerContainer from "./update";
import CreateInsurerManagerContainer from "./add-manager";
import RemoveContainer from "./remove";
import { usePagination } from "hooks";

type Props = {};

const InsurersPage: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState("modal");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { limit, offset, setPage } = usePagination();

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code", "batchCode"],
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );
  const { insurers, refetch, loading, count } = useInsurers(filter);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  const expandItem = (id: string) => {
    navigate({
      to: `./${id}/view`,
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "code" } : {}),
        ...(!old?.viewType ? { viewType: "grid" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <>
      <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
        <OfficeHeader
          renderActions={() => (
            <OfficeButton
              type="button"
              onClick={wrapClick(() => setModal("create"))}
              size="sm"
            >
              <UserPlusIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Add New Insurer
            </OfficeButton>
          )}
        />
        <div className="flex flex-1 overflow-y-auto">
          <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
            <TableComponent
              title={"Insurers"}
              hasSearch
              data={insurers}
              defaultView="grid"
              // renderGridLoader={() => (
              //   <div className="flex space-x-3 items-center h-36 bg-gray-200 animate-pulse rounded-md"></div>
              // )}
              loading={loading}
              refetch={refetch}
              columns={[
                {
                  accessor: "insurer_company_name",
                  name: "Company Name",
                },
                {
                  accessor: "insurer_company_email",
                  name: "Email",
                },
                {
                  accessor: "insurer_company_website",
                  name: "Website",
                },
                {
                  accessor: "id",
                  name: "Remainders",
                  render: (data: Insurers_rows) => (
                    <span>{data?.remainders?.length}</span>
                  ),
                },
                {
                  accessor: "insurer_address.country",
                  name: "Country",
                },
                {
                  accessor: "",
                  name: "Actions",
                  render: (data: Insurers_rows) => (
                    <>
                      <ActionButton
                        action="view"
                        tooltip="view insurer"
                        onClick={dispatchAction(data?.insurer_id, "view")}
                      />
                      <ActionButton
                        action="expand"
                        tooltip="View insurer details"
                        onClick={() => expandItem(data?.insurer_id)}
                      />
                      {withRoles(["CEO", "Senior Broking Officer", 'Finance Officer'])(
                        <ActionButton
                          action="update"
                          tooltip="edit insurer"
                          onClick={dispatchAction(data?.insurer_id, "update")}
                        />
                      )}
                      <ActionButton
                        action="assign"
                        tooltip="add manager"
                        onClick={dispatchAction(data?.insurer_id, "assign")}
                      />
                      {withRoles(["CEO", "Senior Broking Officer", 'Finance Officer'])(
                        <ActionButton
                          action="remove"
                          tooltip="remove insurer"
                          onClick={dispatchAction(data?.insurer_id, "remove")}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              pagination={() => (
                <Paginator
                  offset={offset}
                  limit={limit}
                  totalItems={count}
                  currentSize={insurers.length}
                  setOffset={setPage}
                />
              )}
              renderGridItem={(person: Insurers_rows) => (
                <li
                  key={person.insurer_id}
                  className="col-span-1 flex flex-col divide-y divide-card-600 rounded-lg bg-card-500 text-center shadow"
                >
                  <div className="flex flex-1 flex-col p-8">
                    <div className="flex items-center justify-center">
                      <Avatar
                        size="xl"
                        alt={person.insurer_abbrv?.split("").join(" ") || "N A"}
                      />
                    </div>
                    <h3 className="mt-6 text-sm font-medium text-gray-200">
                      {person.insurer_company_name}
                    </h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dt className="sr-only">Title</dt>
                      <dd className="text-sm text-gray-500">
                        {person.insurer_company_email}
                      </dd>
                    </dl>
                    <div className="mt-1 flex flex-col justify-between bg-primary-100 mx-auto px-3 rounded-3xl">
                      <span className="text-sm text-primary-500">
                        {person.insurer_company_website}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-card-600">
                      <div className="flex w-0 flex-1">
                        <button className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <ActionButton
                            action="view"
                            tooltip="View Insurer"
                            onClick={dispatchAction(person.insurer_id, "view")}
                          />
                        </button>
                      </div>
                      <div className="-ml-px flex w-0 flex-1">
                        <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <ActionButton
                            action="expand"
                            tooltip="Goto Insurer"
                            onClick={() => expandItem(person?.insurer_id)}
                          />
                        </button>
                      </div>
                      {withRoles(["CEO", "Senior Broking Officer", 'Finance Officer'])(
                        <div className="-ml-px flex w-0 flex-1">
                          <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                            <ActionButton
                              action="update"
                              tooltip="Update Insurer"
                              onClick={dispatchAction(person.insurer_id, "update")}
                            />
                          </button>
                        </div>
                      )}
                      <div className="-ml-px flex w-0 flex-1">
                        <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <ActionButton
                            action="assign"
                            tooltip="Add Manager"
                            onClick={dispatchAction(person.insurer_id, "assign")}
                          />
                        </button>
                      </div>
                      {withRoles(["CEO", "Senior Broking Officer", 'Finance Officer'])(
                        <div className="-ml-px flex w-0 flex-1">
                          <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                            <ActionButton
                              action="remove"
                              onClick={dispatchAction(person.insurer_id, "remove")}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              )}
            />
          </div>
        </div>
      </main>

      <CreateEmployeeContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />

      {searchParams.id && (
        <>
          <ViewEmployeeContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <UpdateInsurerContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
            refetch={refetch}
          />
          <CreateInsurerManagerContainer
            open={modal === "assign"}
            setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
            refetch={refetch}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </>
  );
};

export default InsurersPage;
