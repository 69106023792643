

import numeral from 'numeral'
import React from 'react'

type Props = {
    data: any
}

const TreatySearchFormSummary = ({ data }: Props) => {
    return (
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
            <div>
                <div className="grid grid-cols-3 gap-6">
                    <div className=''>
                        <span className="block text-sm font-light text-gray-700">
                            New Policy Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.policy_number || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Insured
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.insured_by || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Sum Insured
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.sum_insured || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Rate
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.rate || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Premioum</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.premium || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreatySearchFormSummary