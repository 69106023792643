import { wrapClick } from "utils";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, OfficeButton } from "components";
import * as Yup from "yup";
import { OfferForm } from "components/forms";
import {
  CreateInputOffer,
  CreateInputOfferVariables,
} from "_graphql/mutation/__generated__/CreateInputOffer";
import { currentUserVar } from "_graphql/cache/auth";
import { createFleetOffer, createOffer } from "_graphql/mutation/offers";
import { useState } from "react";
import pluralize from "pluralize";
import { CreateFleetOffer, CreateFleetOfferVariables } from "_graphql/mutation/__generated__/CreateFleetOffer";

export const valiadationSchema = Yup.object().shape({
  class_of_business_id: Yup.object().required("Business is required"),
  offer_details: Yup.array().notRequired(),
  insurer_id: Yup.string().required("Insurer is required"),
  premium: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Premium is required"),
  policy_number: Yup.string().required("Policy Number is required"),
  insured_by: Yup.string().required("Insured is required"),
  sum_insured: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Sum Insured is required"),
  rate: Yup.number().min(0, "Number must be greater than 0").typeError("Must be a number").required("Rate is required"),
  facultative_offer: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Facultative Offer is required"),
  commission: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Commission is required"),
  brokerage: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Brokerage is required"),
  currency: Yup.string().required("Currency is required"),
  period_of_insurance_from: Yup.date().required(
    "Insurance Start Date is required"
  ),
  // check if end date is greater than start date
  period_of_insurance_to: Yup.date()
    .min(Yup.ref("period_of_insurance_from"), "End Date must be greater than Start Date")
    .required("Insurance End Date is required"),
  // period_of_insurance_to: Yup.date().required("Insurance End Date is required"),
  comment: Yup.string().notRequired(),
});

export default function CreateOfferContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [fleetItems, setFleetitems] = useState<any[]>([]);
  const user = useReactiveVar(currentUserVar);
  const [create, { loading }] = useMutation<
    CreateInputOffer,
    CreateInputOfferVariables
  >(createOffer);
  const [createFleet, { loading: creatingFleet }] = useMutation<
    CreateFleetOffer,
    CreateFleetOfferVariables
  >(createFleetOffer);

  const form = useFormik<any>({
    initialValues: {
      class_of_business_id: "",
      offer_details: [],
      insurer_id: "",
      premium: "",
      policy_number: "",
      insured_by: "",
      sum_insured: "",
      rate: "",
      facultative_offer: "",
      commission: "",
      brokerage: "",
      currency: "",
      period_of_insurance_from: "",
      period_of_insurance_to: "",
      comment: "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      if (fleetItems?.length > 0) {
        await createFleet({
          variables: {
            offerInput: fleetItems.map((item) => ({
              brokerage: parseFloat(item.brokerage),
              insurer_id: item.insurer_id,
              premium: parseFloat(item.premium),
              policy_number: item.policy_number,
              offer_details: JSON.stringify(item.offer_details),
              class_of_business_id:
                item.class_of_business_id?.class_of_business_id,
              employee_id: user?.employee?.employee_id ?? "",
              rate: parseFloat(item.rate),
              commission: parseFloat(item.commission),
              facultative_offer: parseFloat(item.facultative_offer),
              sum_insured: parseFloat(item.sum_insured),
              insured_by: item.insured_by,
              currency: item.currency,
              offer_comment: item.comment,
              period_of_insurance_from: item.period_of_insurance_from,
              period_of_insurance_to: item.period_of_insurance_to,
            })),
          },
        }).then(({ data }) => {
          if (data?.createFleetOffer) {
            toast.success("Offer Created Successfully");
            refetch?.();
            form.resetForm();
          } else {
            toast.error("Failed to create Offer");
          }
        });
      } else {
        await create({
          variables: {
            offerInput: {
              brokerage: parseFloat(variables.brokerage),
              insurer_id: variables.insurer_id,
              premium: parseFloat(variables.premium),
              policy_number: variables.policy_number,
              offer_details: JSON.stringify(variables.offer_details),
              class_of_business_id:
                variables.class_of_business_id?.class_of_business_id,
              employee_id: user?.employee?.employee_id ?? "",
              rate: parseFloat(variables.rate),
              commission: parseFloat(variables.commission),
              facultative_offer: parseFloat(variables.facultative_offer),
              sum_insured: parseFloat(variables.sum_insured),
              insured_by: variables.insured_by,
              currency: variables.currency,
              offer_comment: variables.comment,
              period_of_insurance_from: variables.period_of_insurance_from,
              period_of_insurance_to: variables.period_of_insurance_to,
            },
          },
        }).then(({ data }) => {
          if (data?.createInputOffer.offer_id) {
            toast.success("Offer Created Successfully");
            refetch?.();
            form.resetForm();
          } else {
            toast.error("Failed to create Offer");
          }
        });
      }

    },
    onReset: () => {
      setOpen(false);
    },
  });

  const handleNext = async () => {
    const errors = await form.validateForm();
    if (Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields");
      return;
    } else {
      setFleetitems([...fleetItems, form.values]);
      toast.success("Fleet Item Added, Modify the details and click create offer or add another fleet item");
    }

    // form.resetForm();
  }


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      title="Create New Offer"
      description="Provide the details to add a new offer"
      renderActions={() => (
        <>

          <OfficeButton
            type="button"
            size="sm"
            disabled={loading || creatingFleet}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading || creatingFleet ? "Creating Offer..." : "Create " + (fleetItems?.length > 0 ? pluralize("Offer", fleetItems?.length, true) : "Offer")}
          </OfficeButton>
          {form?.values?.class_of_business_id?.business_name?.includes("Fleet") && (
            <div className="flex items-center">
              <OfficeButton size="sm" variant="secondary" onClick={handleNext}>
                Add Next Fleet Item
              </OfficeButton>

            </div>
          )}

        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <OfferForm form={form} />
    </Modal>
  );
}
