import { Offer_findSingleOffer_offer_participant } from '_graphql/queries/__generated__/Offer'
import { ActionButton, OfficeHeader, TableComponent, TableLoader } from 'components'
import { useOffer } from 'hooks/data/use-offers'
import numeral from 'numeral'
import React, { FC } from 'react'
import { useMatch, useNavigate, useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import ViewNoteContainer from './view'
import { useUrlState } from 'utils'
import { Action } from 'components/buttons/action-button'
import SendEmailsContainer from './send'

type Props = {}

const CreditNotesPage: FC<Props> = (props) => {
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const [modal, setModal] = useUrlState("modal");
    const navigate = useNavigate<LocationGenerics>();


    const { loading, offer, refetch } = useOffer({
        offer_id: params?.offer || "",
    });

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <div>
            <OfficeHeader />

            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        loading={loading}
                        title={'credit notes'}
                        columns={[
                            {
                                name: "Company Name",
                                accessor: "reinsurer.re_company_name",
                            },
                            {
                                name: "Company Email",
                                accessor: "reinsurer.re_company_email",
                            },
                            {
                                name: "Reinsurer Amount",
                                accessor: "offer_amount",
                                render: (value: any) => (
                                    <>
                                        {offer?.offer_detail?.currency} {numeral(value?.offer_amount).format("0,0.00")}
                                    </>
                                ),
                            },
                            {
                                name: "Participant %",
                                accessor: "offer_participant_percentage",
                            },
                            {
                                name: "Action",
                                accessor: "actions",
                                render: (value: Offer_findSingleOffer_offer_participant) => (
                                    <>
                                        <ActionButton action={'clone'} tooltip='Generate Closing Docs' onClick={dispatchAction(value?.offer_participant_id || "", "view")} />
                                        <ActionButton action={'send'} onClick={dispatchAction(value?.reinsurer?.reinsurer_id || "", "send")} />
                                    </>
                                )
                            },
                        ]}
                        data={offer?.offer_participant || []}
                        renderLoader={() => (
                            <TableLoader items={[1, 1, 1, 1, 1]} />
                        )}
                        refetch={refetch} />
                </div>
            </div>

            {searchParams.id && (
                <>
                    <ViewNoteContainer
                        open={modal === "view"}
                        setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
                    />
                    <SendEmailsContainer
                        open={modal === "send"}
                        setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
                    />
                </>
            )}
        </div>
    )
}

export default CreditNotesPage