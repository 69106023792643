import { Modal, PDFRenderer } from "components";
import { useSearch, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";

export default function ViewInsurerPaymentReceiptContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="6xl"
      title="Payment Receipt"
      description="Details of payment receipt are shown below"
    >
      <PDFRenderer
        url={`${config.asset.uri}payment_receipt/${btoa(
          JSON.stringify({
            offer_id: params?.offer,
            payment_id: searchParams?.id,
          })
        )}`}
      />
    </Modal>
  );
}
