import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { OfficeButton, PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { reOpenOffer } from "_graphql/mutation/offers";
import {
  ReopenOffer,
  ReopenOfferVariables,
} from "_graphql/mutation/__generated__/ReopenOffer";

export default function ReopenContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<ReopenOffer, ReopenOfferVariables>(
    reOpenOffer,
    {
      refetchQueries: ["Offers"],
    }
  );

  const handleSubmit = async () => {
    await remove({
      variables: {
        offerId: searchParams?.id || "",
      },
    }).then(({ data }) => {
      if (data?.reopenOffer?.offer_id) {
        toast.success("Offer Reopened Successfully");
        refetch?.();
        setOpen?.(false);
      } else {
        toast.error("Could not reopen offer");
      }
    });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Reopen Offer"
      cancelText="No, Don't Reopen Offer"
      variant="warning"
      description="This action would reopen offer details for editing"
      renderActions={() => (
        <>
          <OfficeButton
            variant="warning"
            size="sm"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? "Reopening Offer..." : "Reopen Offer"}
          </OfficeButton>
        </>
      )}
    />
  );
}
