import { ActionButton, OfficeButton, TableComponent } from "components";
import CreateProportionalPaymentCOntainer from "./create";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useAppLocation } from "_graphql/cache/auth";
import { useProportionalPayments } from "hooks/data/use-treaties";
import moment from "moment";
import { GetProportionalTreatyPayments_getProportionalTreatyPayments } from "_graphql/queries/__generated__/GetProportionalTreatyPayments";
import RemoveContainer from "./remove";
import config from "config";
import DistributePaymentContainer from "./distribute";

type Props = {};

const ProportionalPayments = (props: Props) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { params } = useAppLocation();

  const { loading, payments, refetch } = useProportionalPayments({
    filter: {
      treaty_id: params.treaty,
    },
  });

  const handleGenerateReceipt = (payment: any) => () => {
    window.open(
      `${config.asset.uri}treaty_payment_receipt/${btoa(
        JSON.stringify({
          treaty_id: params?.treaty,
          treaty_payment_id:
            payment.treaty_n_p_payment_id || payment?.treaty_p_payment_id,
        })
      )}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={""}
            loading={loading}
            columns={[
              {
                accessor: "id",
                name: "Payment Type",
                render: (
                  value: GetProportionalTreatyPayments_getProportionalTreatyPayments
                ) => {
                  const details = JSON.parse(
                    value?.treaty_payment_details ?? "{}"
                  );
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_type}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "id",
                name: "Bank Name",
                render: (
                  value: GetProportionalTreatyPayments_getProportionalTreatyPayments
                ) => {
                  const details = JSON.parse(
                    value?.treaty_payment_details ?? "{}"
                  );
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_from?.bank_name}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "id",
                name: "Beneficiary Bank Name",
                render: (
                  value: GetProportionalTreatyPayments_getProportionalTreatyPayments
                ) => {
                  const details = JSON.parse(
                    value?.treaty_payment_details ?? "{}"
                  );
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_to || "N/A"}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "treaty_payment_amount",
                name: "Payment Amt",
                render: (
                  value: GetProportionalTreatyPayments_getProportionalTreatyPayments
                ) => {
                  // const details = JSON.parse(value?.treaty_payment_details ?? "{}")
                  return <span> {value?.treaty_payment_amount}</span>;
                },
              },
              {
                accessor: "created_at",
                name: "Payment Date	",
                render: (value: any) => (
                  <span>{moment(value?.created_at).format("LLL")}</span>
                ),
              },
              {
                accessor: "updated_at",
                name: "Last Payment Upate",
                render: (value: any) => (
                  <span>{moment(value?.updated_at).format("LLL")}</span>
                ),
              },
              {
                accessor: "id",
                name: "Action",
                render: (
                  value: GetProportionalTreatyPayments_getProportionalTreatyPayments
                ) => (
                  <>
                    <ActionButton action={"update"} onClick={() => { }} />
                    <ActionButton
                      action={"clone"}
                      tooltip="View Payment Receipt"
                      onClick={handleGenerateReceipt(value)}
                    />
                    <ActionButton
                      action={"remove"}
                      tooltip="Remove Payment Record"
                      onClick={() =>
                        navigate({
                          search(prev) {
                            return {
                              ...prev,
                              modal: "remove",
                              payment_id: value?.treaty_p_payment_id || "",
                            };
                          },
                        })
                      }
                    />
                    {value?.distribute_payment === "NOT DISTRIBUTED" && (
                      <ActionButton
                        tooltip="Distribute Payment"
                        action={"distribute"}
                        onClick={() => navigate({
                          search(prev) {
                            return {
                              ...prev,
                              id: value?.treaty_p_payment_id || "",
                              modal: "distribute",
                            };
                          },
                        })}
                      />
                    )}
                  </>
                ),
              },
            ]}
            data={payments}
            renderHeaderItems={() => (
              <>
                <OfficeButton
                  onClick={() => {
                    navigate({
                      search(prev) {
                        return {
                          ...prev,
                          modal: "create",
                        };
                      },
                    });
                  }}
                >
                  Make payment
                </OfficeButton>
              </>
            )}
            refetch={refetch}
          />
        </div>
      </div>

      <CreateProportionalPaymentCOntainer
        open={searchParams?.modal === "create"}
        setOpen={(val) =>
          navigate({
            search(prev) {
              return {
                ...prev,
                modal: val ? "create" : undefined,
              };
            },
          })
        }
      />

      {searchParams?.payment_id && (
        <>
          <RemoveContainer
            open={searchParams?.modal === "remove"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "remove" : undefined,
                    payment_id: val ? searchParams?.payment_id : undefined,
                  };
                },
              })
            }
          />
          <DistributePaymentContainer
            open={searchParams?.modal === "distribute"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "distribute" : undefined,
                    id: val ? searchParams?.id : undefined,
                  };
                },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default ProportionalPayments;
