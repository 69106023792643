import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal } from "components";
import { TreatyClaimForm } from "components/forms";
import {
    MakePaymentOnOffer,
    MakePaymentOnOfferVariables,
} from "_graphql/mutation/__generated__/MakePaymentOnOffer";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import numeral from "numeral";
import { createPayment } from "_graphql/mutation/payment";
import { useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import { useTreaty } from "hooks/data/use-treaties";



export default function CreateTreatyClaimContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const currentUser = useCurrentUser();
    const [rate, setRate] = useUrlState("rate");
    const searchParams = useAppSearch()
    const navigate = useNavigate<LocationGenerics>();
    const [create, { loading }] = useMutation<
        MakePaymentOnOffer,
        MakePaymentOnOfferVariables
    >(createPayment);
    const { loading: fetchigOffer, treaty } = useTreaty({
        treatyId: searchParams?.id || "",
    });






    const form = useFormik<any>({
        initialValues: {

        },
        onSubmit: async (variables) => {

        },
        onReset: () => {
            setOpen(false);
        },
    });




    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Make Claim"
            loading={fetchigOffer}
            description="Make a claim on this Treaty"
            hideActions
        >

            <TreatyClaimForm />
        </Modal>
    );
}



