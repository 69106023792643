import { GetClaimShares_getClaimShares } from '_graphql/queries/__generated__/GetClaimShares'
import { ActionButton, OfficeHeader, TableComponent } from 'components'
import { Action } from 'components/buttons/action-button'
import { useOfferClaimShares } from 'hooks/data/use-offer-claims'
import numeral from 'numeral'
import { FC } from 'react'
import { useMatch, useNavigate, useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import ViewDebitNoteContainer from './debit-note'
import CreateReinusrerClaimPaymentContainer from './take-payment'

type Props = {}

const ViewClaimSharesPage: FC<Props> = (props) => {
    const { params } = useMatch<LocationGenerics>()
    const searchParams = useSearch<LocationGenerics>()
    const navigate = useNavigate<LocationGenerics>()
    const { refetch, loading, shares } = useOfferClaimShares({
        offerClaimId: params?.claim || '',
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    const gotoPayments = (id: string) => () => {
        navigate({
            to: `${id}/payments`,
            search(prev) {
                return {
                    ...prev,
                    share: id,
                };
            },
        });
    }


    const takePayemnt = ({ id, share, reinsurer }: any) => () => {
        navigate({
            search: (old) => ({
                ...old,
                id,
                reinsurer,
                amount: share,
                modal: "calculate",
            }),
        });
    };


    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={'shares'}
                        columns={[
                            {
                                accessor: "offer_participant.reinsurer.re_company_name",
                                name: "Reinsurer"
                            },
                            {
                                accessor: "claim_share",
                                render: (value: GetClaimShares_getClaimShares) => <span>{value?.offer_participant?.offer_detail?.currency} {numeral(value?.claim_share).format("#,#.##")}</span>,
                                name: "Share"
                            },
                            {
                                accessor: "id",
                                render: (value: GetClaimShares_getClaimShares) => <span>{value?.offer_participant?.offer_participant_percentage}%</span>,
                                name: "Participating (%)"
                            },
                            {
                                name: "Actions",
                                accessor: "offer_participant.reinsurer.re_company_id",
                                render: (value: GetClaimShares_getClaimShares) => <div className="flex">
                                    <ActionButton action='clone' tooltip='View Debit Note' onClick={dispatchAction(value?.offer_claim_participant_id || "", "view")} />
                                    <ActionButton action='calculate' tooltip='Take Payment' onClick={takePayemnt({ id: value?.offer_claimsoffer_claim_id, share: value?.claim_share, reinsurer: value.offer_participant?.reinsurer?.re_company_name })} />
                                    <ActionButton action='goto' tooltip='View Payments' onClick={gotoPayments(value?.offer_claim_participant_id || "")} />
                                </div>
                            }
                        ]}
                        loading={loading}
                        data={shares}
                        refetch={refetch}
                    />
                </div>
            </div>
            {searchParams.id && (
                <>
                    <ViewDebitNoteContainer open={searchParams?.modal === "view"} setOpen={(value) => navigate({
                        search(prev) {
                            return {
                                ...prev,
                                modal: value ? "view" : undefined,
                                id: value ? prev?.id : undefined,
                            };
                        },
                    })} />
                    <CreateReinusrerClaimPaymentContainer open={searchParams?.modal === "calculate"} setOpen={(value) => navigate({
                        search(prev) {
                            return {
                                ...prev,
                                modal: value ? "calculate" : undefined,
                                id: value ? prev?.id : undefined,
                            };
                        },
                    })} />
                </>
            )}

        </main>
    )
}

export default ViewClaimSharesPage