
import { useFormik } from "formik";
import { useMutation, useReactiveVar } from "@apollo/client";
import toast from "react-hot-toast";
import { StepFormBuilder } from "../..";
import { createTreatyFormItems } from "./form-steps";
import _ from "lodash";
import { currentUserVar } from "_graphql/cache/auth";
import { ManuallyCreateClaims, ManuallyCreateClaimsVariables } from "_graphql/mutation/__generated__/ManuallyCreateClaims";
import { manuallyCreateClaims } from "_graphql/mutation/treaty-claims";

type Props = {
  setClose?: (value: boolean) => void;
};

const TreatyClaimFormContainer = ({ setClose }: Props) => {
  const user = useReactiveVar(currentUserVar);
  const [initiateAddMeeting] = useMutation<ManuallyCreateClaims, ManuallyCreateClaimsVariables>(
    manuallyCreateClaims,
    {
      refetchQueries: ["GetTreatyClaims"],
    }
  );
  const form = useFormik<any>({
    initialValues: {
      treaty: {
        treaty: ""
      },
      claim: {
        treaties: []
      },
      treatyDetails: {
        class_of_business_id: "",
        offer_details: [],
        insurer_id: "",
        premium: "",
        policy_number: "",
        insured_by: "",
        sum_insured: "",
        rate: "",
        facultative_offer: "",
        commission: "",
        brokerage: "",
        currency: "",
        period_of_insurance_from: "",
        period_of_insurance_to: "",
        comment: "",
      },
    },
    onSubmit: async (values) => {
      await initiateAddMeeting({
        variables: {
          claims: values.claim.treaties.map((claim: any) => ({
            claim_paid: claim.claim_paid,
            insured_name: claim.insured,
            layer_number: claim.layer_number,
            claim_date: claim.date_of_loss,
            claim_number: claim.claim_number,
            date_of_loss: claim.date_of_loss,
            expected_deductible: 0,
            policy_number: claim.policy_number,
            treaty_claim_details: JSON.stringify(claim.treaty_claim_details),
            treaty_comment: claim.treaty_comment,
          })),
        }
      }).then((res) => {
        if (res.data?.manuallyCreateClaims) {
          toast.success("Treaty Claim(s) Created Successfully");
          setClose?.(false);
        }
      });
    },
  });


  return <StepFormBuilder formSteps={createTreatyFormItems} form={form} setOpen={setClose} />;
};

export default TreatyClaimFormContainer;



