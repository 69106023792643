import { useQuery } from "@apollo/client";
import { GetTreatyClaims, GetTreatyClaimsVariables } from "_graphql/queries/__generated__/GetTreatyClaims";
import { getTreatyClaims } from "_graphql/queries/treaty-claims";


export const useTreatyClaims = (variables?: GetTreatyClaimsVariables) => {
    const { data, ...rest } = useQuery<GetTreatyClaims, GetTreatyClaimsVariables>(getTreatyClaims, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    return {
        claims: data?.getTreatyClaims || [],
        count: data?.getTreatyClaimsCount || 0,
        ...rest,
    }
}