import { useAppNavigate, useAppSearch } from '_graphql/cache/auth'
import { ActionButton, Avatar, CalendarHeader, OfficeButton, Paginator, RegularInsurerPicker, TableComponent } from 'components'
import { Action } from 'components/buttons/action-button'
import { usePagination } from 'hooks'
import { FC, useState } from 'react'
import CreateTreatyClaimContainer from './create'
import { useTreatyClaims } from 'hooks/data/use-treaty-claims'
import { GetTreatyClaims_getTreatyClaims } from '_graphql/queries/__generated__/GetTreatyClaims'
import moment from 'moment'
import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import { ChevronDownIcon, ChevronUpIcon, DocumentIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-location'
import { classNames } from 'utils'
import numeral from 'numeral'

type Props = {}

const TreatyClaimsPage: FC<Props> = (props) => {
    const navigate = useAppNavigate();
    const searchParams = useAppSearch()
    const { offset, limit, setPage } = usePagination()
    const { claims, count, loading, refetch } = useTreatyClaims({
        treatyId: "",
    })


    const navigateItem =
        (id: string, claim_number: string) => () => {
            console.log('navigateItem', id)
            navigate({
                to: `./${id}/payments/${claim_number?.replaceAll('/', '-')}`,
                search: (old) => ({
                    ...old,
                    policy: claim_number,
                    // layers: layers,
                }),
            });
        };
    const navigateToParticipants =
        (id: string, claim_number: string) => () => {
            console.log('navigateItem', id)
            navigate({
                to: `./${id}/participants/${claim_number?.replaceAll('/', '-')}`,
                search: (old) => ({
                    ...old,
                    policy: claim_number,
                    // layers: layers,
                }),
            });
        };

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <CalendarHeader renderActions={() => (
                <OfficeButton onClick={() => navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: 'create'
                        }
                    },
                })}>
                    Create Claim
                </OfficeButton>
            )} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        loading={loading}
                        title={'treaty claims'}
                        hasSearch
                        columns={[
                            {
                                name: "Policy #",
                                accessor: "treaty_reference",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-col leading-0">
                                        <span className='font-medium text-gray-200'>{value?.policy_number}</span>
                                    </div>
                                ),
                            },
                            {
                                name: "Insured | Date of Loss",
                                accessor: "employee.employee_first_name",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-row leading-0">
                                        <span className='font-medium text-gray-200'>
                                            {value?.insured_name || "N/A"}{" "}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Claim Paid",
                                accessor: "claim_paid",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-col leading-0">
                                        <span className='font-medium text-gray-200'>
                                            {value?.claim_paid || "N/A"}{" "}
                                        </span>
                                    </div>
                                )
                            },
                            {
                                name: "Actions",
                                accessor: "actions",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <>
                                        <ActionButton
                                            action={"view"}
                                            tooltip="View Claim"
                                            onClick={dispatchAction(value?.treaty_claim_id || "", "view")}
                                        />
                                        <ActionButton
                                            action={"update"}
                                            tooltip="Update Claim"
                                            onClick={dispatchAction(value?.treaty_claim_id || "", "update")}
                                        />
                                        <ActionButton
                                            action={"remove"}
                                            tooltip="Remove Claim"
                                            onClick={dispatchAction(value?.treaty_claim_id || "", "update")}
                                        />
                                        <ActionButton
                                            action={"expand"}
                                            tooltip="View Claim Participants & Payments"
                                            onClick={navigateItem(
                                                value?.treaty_claim_id || "",
                                                value?.claim_number || ""
                                            )}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        data={claims}
                        refetch={refetch}
                        renderHeaderItems={() => (
                            <>
                                <RegularInsurerPicker id={'insurer'} label={''} rawId values={{ insurer: searchParams?.insurer }} setFieldValue={(e: any, value: any) => {
                                    console.log('setFieldValue', e, value)
                                }} setFieldTouched={() => { }} setFieldError={() => { }} />
                            </>
                        )}
                        // renderItem={(item: GetTreatyClaims_getTreatyClaims) => <SingleClaim item={item} dispatchAction={dispatchAction} />}

                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={count} currentSize={claims.length} setOffset={setPage} />}
                    />
                </div>
            </div>
            <CreateTreatyClaimContainer open={searchParams?.modal === 'create'} setOpen={(val) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: val ? 'create' : undefined
                    }
                },
            })} />
        </main >
    )
}

export default TreatyClaimsPage


