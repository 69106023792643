import { DocumentTextIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { OfficeButton, TextLoader } from "components";
import { Action } from "components/buttons/action-button";
import { useTreaty } from "hooks/data/use-treaties";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import ViewTreatySlipContainer from "./view-placing-slip";
import { useUrlState } from "utils";
import SendPlacingContainer from "./send-emails";
import _ from "lodash";
import { TreatyType } from "_graphql/__generated__/globalTypes";
import LayerLimits from "./sections/layer-limits";
import TreatyAccounts from "./sections/quarters";
import DuplicateTretyCurrencyContainer from "./duplicate-treaty-currency";

const documents = [
  {
    name: "Placing slip",
    description: "Placing slip",
    icon: <DocumentTextIcon className="h-16 text-gray-400" />,
  },
  {
    name: "Cover note",
    icon: <DocumentTextIcon className="h-16 text-gray-400" />,
  },
];

type Props = {
  allowQuaerterlyFiling: boolean;
};

export default function OfferOverView({ }: Props) {
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const { loading, treaty } = useTreaty({
    treatyId: params.treaty || "",
  });

  const isProportional =
    treaty?.treaty_program?.treaty_type === TreatyType.PROPORTIONAL;

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  return (
    <div className="w-full">
      <div className="items-start px-3 sm:py-6 grid grid-cols-3 gap-6 flex-1">
        <div className="bg-card-500 flex shadow-sm rounded-md flex-col p-3 col-span-2">
          <div className="px-4 sm:px-0 flex justify-between items-center">
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-300">
                Treaty Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Details and information about the treaty
              </p>
            </div>
            <div className="">
              <OfficeButton
                size="sm"
                onClick={() =>
                  navigate({
                    to: `${treaty?.treaty_reference?.replaceAll("/", "__")}/manage-treaty`,
                    search(prev) {
                      return {
                        ...prev,
                      };
                    },
                  })
                }
              >
                Manage Treaty
              </OfficeButton>
              <OfficeButton
                size="sm"
                onClick={() =>
                  navigate({
                    search(prev) {
                      return {
                        ...prev,
                        modal: "clone"
                      };
                    },
                  })
                }
              >
                Treaty Currency
              </OfficeButton>
            </div>
          </div>
          <div className="mt-6 w-full flex-1">
            <dl className="grid grid-cols-1 sm:grid-cols-4">
              <TextLoader
                label="Treaty Program"
                value={treaty?.treaty_program?.treaty_name || ""}
                loading={loading}
              />
              <TextLoader
                label="Reference No."
                value={treaty?.treaty_reference || ""}
                loading={loading}
              />
              <TextLoader
                label="Ceding company"
                value={treaty?.insurer?.insurer_company_name || ""}
                loading={loading}
              />
              <TextLoader
                label="Period"
                value={
                  `${treaty?.treaty_deduction?.treaty_period_from} to ${treaty?.treaty_deduction?.treaty_period_to}` ||
                  ""
                }
                loading={loading}
              />
              <TextLoader
                label="Brokerage"
                value={treaty?.treaty_deduction?.brokerage?.toString() || ""}
                loading={loading}
              />
              <TextLoader
                label="NIC Levy"
                value={treaty?.treaty_deduction?.nic_levy?.toString() || ""}
                loading={loading}
              />
              <TextLoader
                label="Withholding Tax"
                value={
                  treaty?.treaty_deduction?.withholding_tax?.toString() || ""
                }
                loading={loading}
              />
              <TextLoader
                label="Currency"
                value={treaty?.currency || ""}
                loading={loading}
              />
            </dl>
          </div>
        </div>
        <div className="bg-card-500 rounded-md  shadow-sm grid grid-cols-1 gap-4 p-3">
          <ul
            role="list"
            className="divide-y divide-gray-100 rounded-md border border-gray-200"
          >
            {documents.map((document) => (
              // view and send documents here
              <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate text-gray-100 font-medium">
                      {document.name}
                    </span>
                    {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
                  </div>
                </div>
                <div className="ml-4 space-x-3 flex-shrink-0">
                  <a
                    // href="#"
                    onClick={dispatchAction(document.name, "view")}
                    className="font-medium text-gray-200 hover:text-gray-500"
                  >
                    view
                  </a>
                  <a
                    // href="#"
                    onClick={dispatchAction(document.name, "send")}
                    className="font-medium text-gray-200 hover:text-gray-500"
                  >
                    Send
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isProportional && <TreatyAccounts treaty={treaty} />}

      {!isProportional && <LayerLimits treaty={treaty} />}

      {modal && (
        <>
          <ViewTreatySlipContainer
            open={modal === "view"}
            setOpen={(val: boolean) => navigate({
              search(prev) {
                return {
                  ...prev,
                  modal: val ? "view" : undefined,
                  id: undefined,
                };
              },
            })}
          />

          <SendPlacingContainer
            open={modal === "send"}
            setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
          />
          <DuplicateTretyCurrencyContainer
            open={modal === "clone"}
            setOpen={(val: boolean) => setModal(val ? "clone" : undefined)}
          />
        </>
      )}
    </div>
  );
}
