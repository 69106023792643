import {
    RegularSelectInput,
    RichEditor,
    TextInput,
} from "components/core";
import { FormikProps, useFormik } from "formik";
import { Disclosure } from "@headlessui/react";
import _ from "lodash";
import { FC } from "react";
import { wrapClick } from "utils";
import * as Yup from "yup";
import { ChevronUpIcon, TrashIcon } from "@heroicons/react/24/outline";

const validationSchema = Yup.object().shape({
    treaties: Yup.array().of(Yup.object().shape({
        policy_number: Yup.string().required("Policy number is required"),
        claim_number: Yup.string().required("Claim number is required"),
        date_of_loss: Yup.string().required("Date of loss is required"),
        insured: Yup.string().required("Insured is required"),
        claim_paid: Yup.number().typeError("Must be a number").required("Claim paid is required"),
        layer_number: Yup.string().required("Layer number is required"),
        payment_to: Yup.string().required("Payment to is required"),
        treaty_claim_details: Yup.object().shape({
            third_party_claim_amount: Yup.number().typeError("Must be a number").required("Third party claim amount is required"),
            survey_fees: Yup.number().typeError("Must be a number").required("Survey fees is required"),
            legal_fees: Yup.number().typeError("Must be a number").required("Legal fees is required"),
            salvage: Yup.number().typeError("Must be a number").required("Salvage is required"),
            company_name: Yup.string().required("Company name is required"),
            net_liability_amount: Yup.number().typeError("Must be a number").required("Net liability amount is required"),
        }),
        treaty_comment: Yup.string().required("Comment is required"),
    })),
});

type Props = {
    handleNext: (values: any) => void;
    handlePrevious: () => void;
    initialValues: any;
    values: any;
    handleCancel: () => void;
    parentForm: FormikProps<any>;
};

const TreatyClaimForm: FC<Props> = ({ handleCancel, handleNext, initialValues, handlePrevious, parentForm }) => {
    const form = useFormik<any>({
        initialValues: _.get(parentForm.values, "claims", {}),
        onSubmit: (values) => {
            handleNext(values);
        },
        validationSchema,
        onReset: () => {
            handleCancel();
        },
    });

    return (
        <div className="h-full flex-1 flex flex-col">
            <div className="space-y-6 divide-y flex-1 overflow-y-scroll p-6 divide-gray-200">
                <div className="grid grid-cols-4 gap-4 bg-gray-200 border border-dashed border-gray-400 rounded-lg p-3">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Reinsured
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {parentForm?.values?.treaty?.selectedTreaty?.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Reinsured Email
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {parentForm?.values?.treaty?.selectedTreaty?.insurer?.insurer_company_email || "N/A"}
                        </div>
                    </div>
                </div>
                {/* {JSON.stringify(form.values, null, 2)} */}
                {/* Disclosures for Selected Treaties */}
                <div className="pt-3 space-y-6">
                    {form?.values?.treaties?.map((selectedOffer: any, index: number) => (
                        <Disclosure defaultOpen={index === 0} key={selectedOffer.treaty_id}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button disabled={form?.values?.treaties?.length === 1} className="flex w-full justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                        <div>
                                            <span>{`Claim #${index + 1}`}</span>
                                        </div>
                                        <div className="flex space-x-2">
                                            {index !== 0 && (
                                                <TrashIcon className="h-5 w-5 text-red-500" onClick={wrapClick(() => form.setFieldValue("treaties", form.values.treaties.filter((_: any, i: number) => i !== index)))} />
                                            )}
                                            <ChevronUpIcon
                                                className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`}
                                            />
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <div className="space-y-4">
                                            <div className="grid grid-cols-2 gap-2">
                                                <div>
                                                    <TextInput
                                                        type="text"
                                                        id={`treaties.${index}.policy_number`}
                                                        disabled
                                                        label="Policy Number"
                                                        placeholder="e.g. NCA-123-6457373"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div>
                                                    <TextInput
                                                        type="text"
                                                        id={`treaties.${index}.claim_number`}
                                                        label="Claim Number"
                                                        placeholder="e.g. NCA-123-6457373"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div>
                                                    <TextInput
                                                        type="date"
                                                        id={`treaties.${index}.date_of_loss`}
                                                        label="Date of Loss"
                                                        placeholder="e.g. NCA-123-6457373"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div>
                                                    <TextInput
                                                        type="text"
                                                        id={`treaties.${index}.insured`}
                                                        label="Insured"
                                                        placeholder="e.g. Kwame Nkrumah"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextInput
                                                        type="text"
                                                        id={`treaties.${index}.claim_paid`}
                                                        label="Claim Paid"
                                                        placeholder="e.g. 1000000"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div>
                                                    <RegularSelectInput
                                                        options={(JSON.parse(parentForm?.values?.treaty?.selectedTreaty.layer_limit || "[]") || []).map((layer: any, i: number) => ({
                                                            value: i + 1,
                                                            label: `Layer ${i + 1}`,
                                                        }))}
                                                        id={`treaties.${index}.layer_number`}
                                                        label="Layer Number"
                                                        placeholder="Select Layer"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                                <div>
                                                    <TextInput
                                                        type="number"
                                                        id={`treaties.${index}.expected_deductible`}
                                                        label="Deductible"
                                                        placeholder="e.g. 1200"
                                                        required={true}
                                                        {...form}
                                                    />
                                                </div>
                                            </div>

                                            {/* Claim Details Section */}
                                            <div className="pt-3">
                                                <span className="text-xs font-light">Claim Details</span>
                                                <div className="grid grid-cols-2 gap-2">
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.third_party_claim_amount`}
                                                            label="Plus(+) Third Party Claim Amount"
                                                            placeholder="Third Party Claim Amount"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.survey_fees`}
                                                            label="Plus(+) Third Survey fees"
                                                            placeholder="Third Survey fees"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>
                                                    {/* Additional Claim Details... */}
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.legal_fees`}
                                                            label="Plus(+) Legal fees"
                                                            placeholder="Legal fees"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.salvage`}

                                                            label="Minus(-) Salvage"
                                                            placeholder="Salvage"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.company_name`}
                                                            label="Company name"
                                                            placeholder="Company name"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            type="number"
                                                            step={0.01}
                                                            id={`treaties.${index}.treaty_claim_details.net_liability_amount`}
                                                            label="Plus(+) Net liability amount"
                                                            placeholder="Net liability amount"
                                                            required={true}
                                                            {...form}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="pt-3">
                                                    <div className="grid grid-cols-2 gap-2">
                                                        <div className="col-span-2">
                                                            <RichEditor id={`treaties.${index}.treaty_comment`}
                                                                label="Claim Comment"
                                                                placeholder="Comment"
                                                                required={true}
                                                                {...form} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                    <div
                        onClick={wrapClick(() => form.setFieldValue("treaties", [...form.values.treaties, { policy_number: parentForm?.values?.treaty?.selectedTreaty?.treaty_reference, claim_number: "", date_of_loss: "", insured: "", claim_paid: "", layer_number: "", payment_to: "", treaty_claim_details: { third_party_claim_amount: "", survey_fees: "", legal_fees: "", salvage: "", company_name: "", net_liability_amount: "" }, treaty_comment: "" }]))}
                        className="flex w-full justify-center cursor-pointer rounded-lg bg-gray-100 items-center px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="text-center">Add Another Claim</span>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                <button
                    type="button"
                    onClick={wrapClick(form.handleSubmit)}
                    disabled={!form.isValid}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Next
                </button>
                <button
                    type="button"
                    onClick={wrapClick(handlePrevious)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Previous
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={wrapClick(form.resetForm)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default TreatyClaimForm;
