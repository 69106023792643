import { gql } from "@apollo/client";


export const getTreatyClaims = gql`
query GetTreatyClaims($treatyId: ID) {
  getTreatyClaimsCount(treaty_id: $treatyId)
  getTreatyClaims(treaty_id: $treatyId) {
    treaty_claim_id
    policy_number
    claim_number
    insured_name
    date_of_loss
    claim_date
    claim_paid
    layer_number
    expected_deductible
    treaty_comment
    treaty_claim_details
    reinstatement_premium
    treaty {
      treaty_id
      treaty_reference
      kek_reference
      treaty_details
      currency
      order_hereon
      treaty_payment_status
      layer_limit
      treaty_participants {
        treaty_participation_id
        treaty_participation_percentage
        layer_number
        reinsurer {
          re_company_email
          re_abbrv
          re_company_name
        }
      }
    }
    receivable_payments {
      receivable_payment_id
      uuid
      payment_amount
      payment_detail
      created_at
    }
  }
}
`;