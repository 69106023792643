import { Modal } from 'components';
import { FC } from 'react'

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const ViewClaimContainer: FC<Props> = ({ open, setOpen }) => {
    return (
        <Modal
            open={open}
            setOpen={(value) => {
                setOpen(value);
            }}
            size="4xl"
            loading={false}
            title="View Claim"
            description="Distribution of claim"
        >

        </Modal>
    )
}

export default ViewClaimContainer